import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './AuctionDetail.scss';
import { getAuction, getAllAuctions as getAllAuctionsService } from '../../services/AuctionServices';
import Loading from '../loading/Loading';
import moment from 'moment';
import 'moment/locale/es';
import { Constants } from '../../Constants';
import { connect } from 'react-redux'
import { addOffer, deleteOffer } from '../../services/OffersServices';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { logoutAction } from '../../redux/actions/UserActions';
import { confirmAlert } from 'react-confirm-alert';
import calendar from '../../assets/icons/calendar.svg';
import CountLots from './CountLots';
//import download from '../../assets/icons/download.svg';
import { Carousel } from 'react-bootstrap';

class AuctionDetail extends Component {
    state = {
        id: this.props.match.params.id,
        auction: {
            title: '',
            description: '',
            dateDescription: '',
            lots: {},
            secondsStart: '',
            minutesStart: '',
            pending: false,
            catalog: ''
        },
        loading: false,
        intervalAuction: null,
        intervalCountDown: null,
        intervalGetDateTime: null,
        intervalCountDownStart: null,
        closingLots: false,
        positionY: 0,
        title: `Subasta #${this.props.match.params.id}`,
        timestamp: null,
        apiKey: null
    }

    componentDidMount = async () => {
        let title = this.state.title;
        if (this.props.configAccount.config?.title_tabs) {
            title = this.props.configAccount.config.title_tabs + ' - ' + title;
        }
        document.title = title;
        toast.configure();
        moment.locale('es');
        await this.fetchAuction();
        let interval = setInterval(() => {
            this.fetchAuction(false, 1);
        }, 15000);
        await this.setState({
            intervalAuction: interval,
            apiKey: this.props.configAccount?.config?.socket_api_key
        });
        if (this.state.apiKey) {
            this.subscribeSocket();
        }
        document.addEventListener("scroll", () => {
            this.setState({
                positionY: window.scrollY
            })
        });
    }

    componentDidUpdate = async (prevProps) => {
        if (prevProps.configAccount.config !== this.props.configAccount.config) {
            if (this.props.configAccount.config?.title_tabs !== null) {
                document.title = this.props.configAccount.config.title_tabs + ' - ' + this.state.title;
            }
            if (this.props.configAccount.config?.socket_api_key !== null && this.props.configAccount.config?.socket_api_key !== this.state.apiKey) {
                await this.setState({
                    apiKey: this.props.configAccount?.config?.socket_api_key
                });
                this.subscribeSocket();
            }
        }
    }

    componentWillReceiveProps = async (newProps) => {
        if (this.state.id !== newProps.match.params.id) {
            await this.setState({
                id: newProps.match.params.id
            })
            this.fetchAuction();
        }
    }

    subscribeSocket = async () => {
        let piesocket = new WebSocket(this.state.apiKey);
        console.log('Connected to the socket server');
        piesocket.onmessage = async (message) => {
            let data = JSON.parse(message.data);
            if (data.socket_io_customer === window.socket_io_customer) {
                let lot = data.lot;
                if (lot && lot.id_remate === this.state.auction.id) {
                    let lots = this.state.auction.lots;
                    let id_lot = lot.id;
                    if (window.socket_io_customer === 'remates_cardozo') {
                        if (lot.id_lot.includes('-A')) {
                            id_lot = lot.id_lot.replace('-A', '.1')
                        }
                        else if (lot.id_lot.includes('-B')) {
                            id_lot = lot.id_lot.replace('-B', '.2')
                        }
                        else if (lot.id_lot.includes('-C')) {
                            id_lot = lot.id_lot.replace('-C', '.3')
                        }
                        id_lot = parseFloat(id_lot).toFixed(2);
                    }

                    if (lots[id_lot].manualOffer > lot.manualOffer && !lots[id_lot].is_delete_offer) {
                        lot.manualOffer = lots[id_lot].manualOffer;
                    }
                    lot.image_lot = lots[id_lot]?.image_lot;
                    lot.image_remision = lots[id_lot]?.image_remision;
                    lot.participation = lot.participations.filter(item => item === this.props.sessionProps?.account?.id).length > 0 ? true : null;
                    lots[id_lot] = lot;




                    await this.setState({
                        auction: {
                            ...this.state.auction,
                            lots: lots
                        },
                        timestamp: data.timestamp ? data.timestamp : this.state.timestamp
                    });
                }
            }
        }
    }

    fetchAuction = async (showLoading = true, interval = 0) => {
        showLoading && await this.setState({ loading: true });
        try {
            let token = this.props.sessionProps.account && this.props.sessionProps.account.token ? this.props.sessionProps.account.token : null;
            let response = await getAuction({ id: this.state.id, token: token, interval: interval });
            if (showLoading && response.data.data.version !== Constants.VERSION) {
                toast.error("La versión de la aplicación ha sido actualizada, refresque la página por favor", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
            let lots = response.data.data.lots;
            let __lots = this.state.auction.lots;
            lots.map(item => {
                let id_lot = item.id
                if (window.socket_io_customer === 'remates_cardozo') {
                    if (item.id_lot.includes('-A')) {
                        id_lot = item.id_lot.replace('-A', '.1')
                    }
                    else if (item.id_lot.includes('-B')) {
                        id_lot = item.id_lot.replace('-B', '.2')
                    }
                    else if (item.id_lot.includes('-C')) {
                        id_lot = item.id_lot.replace('-C', '.3')
                    }
                    id_lot = parseFloat(id_lot).toFixed(2);
                }
                if (__lots[id_lot]) {
                    if (__lots[id_lot].manualOffer > item.manualOffer) {
                        item.manualOffer = __lots[id_lot].manualOffer;
                    }
                }
                __lots[id_lot] = item;
            });
            await this.setState({
                auction: {
                    ...this.state.auction,
                    ...response.data.data,
                    dateTime: response.data.data.dateTime.date,
                    lots: __lots,
                    status: this.getEstado(response.data.data)
                },
                loading: false,
                timestamp: response.data.timestamp
            });
            if (this.state.auction.starting) {
                this.getCountDown();
            }
            if (this.state.auction.pending) {
                this.getCountDownStart();
            }
            if (showLoading && this.props.match.params.y !== undefined) {
                window.scrollTo(0, this.props.match.params.y);
                this.setState({
                    positionY: this.props.match.params.y
                })
                // elimino el param y de la url
                const { history, location } = this.props;
                const pathParts = location.pathname.split('/');
                // Eliminamos el último segmento de la ruta, que corresponde al parámetro "y"
                pathParts.pop();
                const newPath = pathParts.join('/');
                // Usamos history.replace para actualizar la URL sin recargar la página
                history.replace(newPath);
            }
        } catch (error) {
            await this.setState({ loading: false });
            if (error?.response?.status === 404) {
                const { data } = await getAllAuctionsService();
                if (data?.data?.inProgress && data?.data?.inProgress.length) {
                    let id = data.data.inProgress[0].id;
                    this.props.history.push(`/subastas/${id}`);
                }
                else {
                    this.props.history.push(`/`);
                }
            }
        }
    }

    getEstado = (auction) => {
        let dateFrom = auction.date_from;
        let dateTo = auction.date_to;
        let dateTime = auction.dateTime.date;
        if (auction.lots.length > 0) {
            let lots = [...auction.lots];
            lots.sort(function (a, b) {
                if (a.real_date_close > b.real_date_close) return 1;
                if (a.real_date_close < b.real_date_close) return -1;
                return 0;
            });
            dateTo = lots[lots.length - 1].real_date_close;
        }
        if (moment(auction.date_to) > moment(dateTime) && moment(dateFrom) < moment(dateTime)) {
            return 'Subasta en proceso';
        }
        if (moment(dateTo) > moment(dateTime) && moment(dateFrom) < moment(dateTime)) {
            return 'Cerrando subasta';
        }
        if (moment(dateTo) < moment(dateTime) && moment(dateFrom) < moment(dateTime)) {
            return 'Subasta finalizada';
        }
        return 'Subasta pendiente';
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    handleChange = (event, item) => {
        const { name, value } = event.target;
        let valor = parseInt(Math.round(value / 10) * 10);
        if (valor < item.nextOffer) {
            item.manualOffer = item.nextOffer;
        }
        else {
            item.manualOffer = valor;
        }
    }

    offer = (item) => {
        let answer = false;
        if (item.nextOffer > 1000) {
            if (item.manualOffer >= item.nextOffer + item.nextOffer * 1.60) {
                answer = true;
            }
        }
        else {
            if (item.manualOffer >= item.nextOffer * 2) {
                answer = true;
            }
        }
        if (answer) {
            confirmAlert({
                title: `¿Esta seguro que desea continuar?`,
                message: `Su oferta de ${this.state.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(item.manualOffer)} supera altamente la oferta actual`,
                buttons: [
                    {
                        label: 'Cancelar'
                    },
                    {
                        label: 'Confirmar',
                        onClick: () => {
                            this._offer(item)
                        }
                    }
                ]
            });
        }
        else if (window.use_confirm_offer) {
            confirmAlert({
                title: `Confirmar oferta`,
                message: `¿Esta seguro que desea confirmar la oferta?`,
                buttons: [
                    {
                        label: 'Cancelar'
                    },
                    {
                        label: 'Confirmar',
                        onClick: () => {
                            this._offer(item)
                        }
                    }
                ]
            });
        }
        else {
            this._offer(item)
        }
    }

    _offer = async (item) => {
        await this.setState({ loading: true });
        try {
            let response = await addOffer({
                token: this.props.sessionProps.account.token,
                id_lot: item.id,
                offer: item.manualOffer > item.nextOffer ? item.manualOffer : item.nextOffer
            });
            let lots = this.state.auction.lots;
            let lot = response.data.data.lot;
            let id_lot = item.id
            if (window.socket_io_customer === 'remates_cardozo') {
                if (item.id_lot.includes('-A')) {
                    id_lot = item.id_lot.replace('-A', '.1')
                }
                else if (item.id_lot.includes('-B')) {
                    id_lot = item.id_lot.replace('-B', '.2')
                }
                else if (item.id_lot.includes('-C')) {
                    id_lot = item.id_lot.replace('-C', '.3')
                }
                id_lot = parseFloat(id_lot).toFixed(2);
            }
            lot.image_lot = lots[id_lot]?.image_lot;
            lot.image_remision = lots[id_lot]?.image_remision;
            lots[id_lot] = lot;
            await this.setState({
                auction: {
                    ...this.state.auction,
                    lots: lots
                },
                loading: false
            });
            toast("Tu oferta ha sido ingresada con éxito", {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error.response.status === 401) {
                this.props.logout();
                this.props.history.push(`/ingresar/${this.state.id}/${this.state.positionY}/subastas`);
            }
            else {
                toast.error("No fue posible ingresar tu oferta, reintentar nuevamente", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    getCountDown = async () => {
        if (this.state.intervalCountDown !== null) {
            clearInterval(this.state.intervalCountDown);
        }
        let interval = setInterval(async function () {
            await this.setState({
                timestamp: this.state.timestamp + 1
            })
        }.bind(this), 1000);
        await this.setState({
            intervalCountDown: interval
        });
    }

    _countDown = ({ item }) => {
        if (this.state.timestamp === null || item.timestampClose === null) {
            return null;
        }
        //
        let difference = new Date(item.timestampClose * 1000).getTime() - new Date(this.state.timestamp * 1000).getTime();
        let seconds = Math.floor(difference / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);
        hours %= 24;
        minutes %= 60;
        seconds %= 60;
        if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
            if (item.status === Constants.STATUS_IN_PROGRESS) {
                item.timestampClose = null;
                item.countDownVisible = false;
                if (item.bestUserIdOffer !== null) {
                    item.status = Constants.STATUS_PENDING;
                    this.setItem(item);
                } else {
                    item.status = Constants.STATUS_NOT_AUCTIONED;
                    this.setItem(item);
                }
            }
            return null;
        }
        //
        if (days === 0 && hours === 0 && minutes === 0 && seconds <= 5 && seconds > 0) {
            if (item.message !== 'Cerrando lote...') {
                item.message = 'Cerrando lote...';
                this.setItem(item);
            }
            return (
                <React.Fragment>
                    <div className="timeclock">
                        <div>
                            Cerrando lote...
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        //
        if (days === 0 && hours === 0 && (minutes < 2 || minutes === 2 && seconds === 0)) {
            if (item.countDownVisible !== true) {
                item.countDownVisible = true;
                this.setItem(item);
            }
            if (seconds.toString().length === 1) {
                seconds = '0' + seconds;
            }
            if (minutes.toString().length === 1) {
                minutes = '0' + minutes;
            }
            return (
                <React.Fragment>
                    <div className="timeclock">
                        <div>
                            {`00:${minutes}:${seconds}`}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        //
        return null;
    }

    countDown = ({ item }) => {
        let countDown = this._countDown({ item });
        if (countDown !== null) {
            return countDown;
        }
        return (<React.Fragment></React.Fragment>);
    }

    setItem = async (item) => {
        let lots = this.state.auction.lots;
        let id_lot = item.id
        if (window.socket_io_customer === 'remates_cardozo') {
            if (item.id_lot.includes('-A')) {
                id_lot = item.id_lot.replace('-A', '.1')
            }
            else if (item.id_lot.includes('-B')) {
                id_lot = item.id_lot.replace('-B', '.2')
            }
            else if (item.id_lot.includes('-C')) {
                id_lot = item.id_lot.replace('-C', '.3')
            }
            id_lot = parseFloat(id_lot).toFixed(2);
        }
        lots[id_lot] = item;
        await this.setState({
            auction: {
                ...this.state.auction,
                lots: lots
            }
        });
    }

    countDownCancelOffer = ({ item }) => {
        try {
            if (this._countDown({ item }) !== null || this.props.sessionProps.account === null || this.props.sessionProps.account.id !== item.bestUserIdOffer) {
                return (<React.Fragment></React.Fragment>);
            }
            if (this.state.timestamp === null || item.timestampCancelOffer === null) {
                return (<React.Fragment></React.Fragment>);
            }
            //
            let difference = new Date(item.timestampCancelOffer * 1000).getTime() - new Date(this.state.timestamp * 1000).getTime();
            let seconds = Math.floor(difference / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);
            hours %= 24;
            minutes %= 60;
            seconds %= 60;
            //
            if (days === -1 && hours === -1 && minutes === -1 && seconds + 30 <= 30 && seconds + 30 >= 0) {
                return (
                    <div style={{ textAlign: 'center', marginTop: '0.5rem' }}>
                        <button onClick={() => this.deleteOffer(item)} style={{ background: 'transparent', border: 'none', color: 'red', textDecoration: 'underline' }}>
                            {`Cancelar oferta (${seconds + 30})`}
                        </button>
                    </div>
                );
            }
            //
            return (<React.Fragment></React.Fragment>);
        } catch (error) {
            return (<React.Fragment></React.Fragment>);
        }
    }

    getCountDownStart = async () => {
        if (this.state.intervalCountDownStart !== null) {
            clearInterval(this.state.intervalCountDownStart);
        }
        let interval = setInterval(function () {
            this.processCountDownStart();
        }.bind(this), 1000);
        await this.setState({
            intervalCountDownStart: interval
        });
    }

    processCountDownStart = () => {
        let auction = this.state.auction;
        var days = null; var hours = null; var minutes = null; var seconds = null;
        if (auction.timeLeft !== null) {
            let { day, hour, minute, second } = auction.timeLeft;
            days = parseInt(day);
            hours = parseInt(hour);
            minutes = parseInt(minute);
            seconds = parseInt(second);
            let pending = true;
            if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
                this.fetchAuction(false);
                pending = false;
                auction.timeLeft = null;
            }
            if (pending) {
                if (seconds === 0) {
                    minutes = minutes - 1;
                    seconds = 59;
                }
                else {
                    seconds = seconds - 1;
                }
                auction.timeLeft.second = seconds.toString().length === 1 ? '0' + seconds.toString() : seconds.toString();
                auction.timeLeft.minute = minutes.toString().length === 1 ? '0' + minutes.toString() : minutes.toString();
            }
            this.setState({
                auction: {
                    ...this.state.auction,
                    pending: pending,
                    timeLeft: auction.timeLeft
                }
            });
        }
    }

    componentWillUnmount = () => {
        this.state.intervalAuction !== null && clearInterval(this.state.intervalAuction);
        this.state.intervalCountDown !== null && clearInterval(this.state.intervalCountDown);
        this.state.intervalGetDateTime !== null && clearInterval(this.state.intervalGetDateTime);
    }

    deleteOffer = (item) => {
        confirmAlert({
            title: `Cancelar oferta`,
            message: `¿Esta seguro que desea cancelar la oferta? `,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._deleteOffer(item)
                    }
                }
            ]
        });
    }

    _deleteOffer = async (item) => {
        await this.setState({ loading: true })
        try {
            item.is_delete_offer = true;
            await this.setItem(item);
            let response = await deleteOffer({
                token: this.props.sessionProps.account.token,
                id: item.offerId,
                lot: item.id
            });
            await this.setState({ loading: false });
            toast(`La oferta ha sido cancelada con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    updateUrl = (url = null) => {
        this.props.history.push(window.location.pathname + '/' + this.state.positionY);
        if (url) {
            window.location.href = url;
        }
    }

    card = ({ item, index }) => (
        <React.Fragment>
            <div className={`${this._countDown({ item }) !== null ? 'col-lg-3 col-md-6' : 'col-lg-4 col-md-6'} card-lot`}>
                {
                    this.countDown({ item })
                }
                <div style={{ position: 'relative' }}>
                    {
                        window.socket_io_customer !== 'luisina_veiga' ?
                            <CountLots text={`LOTE: ${item.id_lot}`} left={true} />
                            : null
                    }
                    {
                        item.extraImages ?
                            <div className="single-collections-box">
                                <Carousel variant="dark" interval={null}>
                                    {item.extraImages.map((image, index) =>
                                        <Carousel.Item>
                                            <img
                                                key={index}
                                                onClick={() => this.updateUrl(`/lotes/${item.id}/${this.state.positionY}`)}
                                                style={item.status === 2 || item.status === 3 || item.status === 4 ?
                                                    { opacity: '0.5', cursor: 'pointer' } : { cursor: 'pointer' }}
                                                className="d-block w-100"
                                                src={`${image.image}`}
                                                alt="Lote sin imagen"
                                            />
                                        </Carousel.Item>
                                    )}
                                </Carousel>
                            </div>
                            :
                            <Link onClick={() => this.updateUrl()} to={`/lotes/${item.id}/${this.state.positionY}`} style={item.status === 2 || item.status === 3 || item.status === 4 ? { opacity: '0.5' } : {}}>
                                <a>
                                    <div className="product-image">
                                        {
                                            item.image_lot?.includes(Constants.BASE_URL_IMAGES) ?
                                                <img src={`${item.image_lot}`} alt="Lote sin imagen" />
                                                : <img src={`${Constants.BASE_URL}${item.image_lot}`} alt="Lote sin imagen" />
                                        }
                                        {
                                            item.id_remate === 36 && window.socket_io_customer === 'humberto_soto_remates' && (
                                                <div style={{
                                                    position: 'absolute',
                                                    bottom: '5px',
                                                    right: '5px',
                                                    background: 'yellow',
                                                    color: 'black',
                                                    padding: '0.125rem 1rem',
                                                    cursor: 'pointer',
                                                    fontSize: '14px',
                                                    fontWeight: '600'
                                                }}>
                                                    Ver más detalles
                                                </div>
                                            )
                                        }
                                    </div>
                                </a>
                            </Link>
                    }

                    <div className="text-left" style={{ border: '1px solid #e9e9e9', borderTop: 'none' }}>
                        {
                            window.socket_io_customer === 'luisina_veiga' ?
                                <p className="id-lot">
                                    <b>LOTE:</b> {item.id_lot}
                                </p> : null
                        }
                        <p className="title-lot">
                            {item.title}
                        </p>
                        {
                            window.socket_io_customer !== 'luisina_veiga' && window.socket_io_customer !== 'remates_de_maria' ?
                                <p className="quantity-lot">
                                    <b>Cantidad:</b> {item.quantity}
                                </p> : null
                        }
                        {
                            item.description === null || item.description === '' ?
                                <p className="description-without">-</p>
                                : <p className="description-with" dangerouslySetInnerHTML={{ __html: item.description }} />
                        }
                        <p className="date-close-and-price">
                            <b>Cierre:</b>
                            {` ${this.capitalize(moment(item.date_close).format('dddd D'))} de 
                                                  ${this.capitalize(moment(item.date_close).format('MMMM'))},
                                                  ${moment(item.date_close).format('HH:mm')} hs`}
                        </p>
                        {
                            window.socket_io_customer === 'luisina_veiga' ?
                                <p className="quantity-lot">
                                    <b>Cantidad:</b> {item.quantity}
                                </p> : null
                        }
                        <p className="date-close-and-price"><b>Precio base:</b>
                            {
                                item.price_base > 0 ?
                                    ` ${this.state.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(item.price_base)}` : ' Sin precio base'
                            }
                        </p>
                        {
                            item.status === Constants.STATUS_IN_PROGRESS && (item.message === undefined || item.message === null) ?
                                <React.Fragment>
                                    <p className="actual-offer"><b>Oferta actual:{` ${this.state.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(item.offer)}`}</b></p>
                                    {
                                        item.bestUserNameOffer &&
                                        <p className="best-offer-user"><b>Ganador actual:</b> {item.bestUserNameOffer}</p>
                                    }
                                    <div className="buy-checkbox-btn">
                                        <div className="item">
                                            {
                                                this.props.sessionProps.account === null &&
                                                <Link to={`/ingresar/${this.state.id}/${this.state.positionY}/subastas`}>
                                                    <button className={`btn btn-primary not_offered`}>
                                                        INGRESAR PARA OFERTAR
                                                    </button>
                                                </Link>
                                            }
                                            {
                                                (this.props.sessionProps.account !== null && this.props.sessionProps.account.id !== item.bestUserIdOffer) ?
                                                    item.participation ?
                                                        <Link to="#">
                                                            <button className={`btn btn-primary not_offered`}
                                                                onClick={() => this.offer(item)}>
                                                                OFERTAR {` ${this.state.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(item.manualOffer)}`}
                                                            </button>
                                                        </Link>
                                                        :
                                                        <Link to="#">
                                                            <button className={`btn btn-primary not_offered`}
                                                                onClick={() => this.offer(item)}>
                                                                OFERTAR {` ${this.state.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(item.manualOffer)}`}
                                                            </button>
                                                        </Link>
                                                    : null
                                            }
                                            {
                                                this.props.sessionProps.account !== null && this.props.sessionProps.account.id === item.bestUserIdOffer &&
                                                <Link to="#">
                                                    <button className="btn btn-primary winning" disabled={true}>
                                                        <i className="fas fa-star"></i> MEJOR OFERTA
                                                    </button>
                                                </Link>
                                            }
                                        </div>
                                        {
                                            this.props.sessionProps.account !== null && this.props.sessionProps.account.id !== item.bestUserIdOffer &&
                                            <div className="item text-center" style={{ marginTop: '5px' }}>
                                                <div className="product-add-to-cart">
                                                    <div className="input-counter" style={{ width: '100%', maxWidth: '100%' }} >
                                                        <input type="number" min={item.nextOffer} step="10" placeholder="Ingresar monto manual" style={{ width: '100%', textAlign: 'center' }}
                                                            onChange={(event) => this.handleChange(event, item)} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.countDownCancelOffer({ item })
                                        }
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {
                                        item.status === Constants.STATUS_PENDING || item.status === Constants.STATUS_DELIVERED ?
                                            <React.Fragment>
                                                {
                                                    item.bestUserNameOffer &&
                                                    <p className="best-offer-user"><b>Ganador actual:</b> {item.bestUserNameOffer}</p>
                                                }
                                                {
                                                    this.props.sessionProps.account !== null && this.props.sessionProps.account.id === item.bestUserIdOffer ?
                                                        <Link to="#">
                                                            <button className="btn btn-primary winning" disabled={true}>
                                                                <i className="fas fa-star"></i> SUBASTADO {this.state.auction.moneySymbol} {new Intl.NumberFormat("de-DE").format(item.offer)}
                                                            </button>
                                                        </Link>
                                                        :
                                                        <Link to="#" className="noHover">
                                                            <button disabled={true} className={`btn btn-primary auctioned`} >
                                                                SUBASTADO {this.state.auction.moneySymbol} {new Intl.NumberFormat("de-DE").format(item.offer)}
                                                            </button>
                                                        </Link>
                                                }
                                            </React.Fragment> : null
                                    }
                                    {
                                        item.status === Constants.STATUS_NOT_AUCTIONED &&
                                        <React.Fragment>
                                            {
                                                item.bestUserNameOffer &&
                                                <p className="best-offer-user"><b>Ganador actual:</b> {item.bestUserNameOffer}</p>
                                            }
                                            <Link to="#" className="noHover">
                                                <button className="btn btn-primary not_auctioned" disabled={true}>
                                                    NO SUBASTADO
                                                </button>
                                            </Link>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

    render() {
        return (
            <section className={`${this.props.sessionProps.account !== null ? 'pb-60' : 'ptb-60'}`} id="auction-detail">
                {this.state.loading && <Loading />}
                <div className="container">
                    {
                        this.props.sessionProps.account !== null &&
                        <div className="row" style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                            <div className="col-12 text-right">
                                <div style={{ width: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                    <span onClick={() => this.props.history.push(`/mi-cuenta/1/${this.state.id}/subastas`)} className="item-myaccount"> <i className="fas fa-user"></i> Mi cuenta</span>
                                    <span onClick={() => this.props.history.push(`/mi-cuenta/2/${this.state.id}/subastas`)} className="item-myaccount"><i className="fas fa-gavel"></i>  Mis ofertas</span>
                                    <span onClick={() => this.props.history.push(`/mi-cuenta/3/${this.state.id}/subastas`)} className="item-myaccount"><i className="fas fa-shopping-basket"></i>  Mis Compras</span>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2 class="color-secondary">{this.state.auction.title}</h2>
                            <label className="state-title">ESTADO:</label>
                            <label className="state-content">{this.state.auction.status}</label>
                        </div>
                        <div className="col-12 col-md-10 ml-auto mr-auto text-left">
                            <div className="date-from-to desktop" style={{ display: 'flex' }}>
                                <img src={calendar} alt="calendar" className="" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', width: '50px', height: '50px' }} />
                                <div style={{ marginLeft: '1rem', textAlign: 'left', display: 'flex' }}>
                                    <div style={{ marginLeft: '1rem' }}>
                                        <label>INICIO</label>
                                        <br />
                                        <label>CIERRE</label>
                                    </div>
                                    <div style={{ marginLeft: '1rem' }}>
                                        <label style={{ color: '#323232', fontWeight: 'bold' }}>
                                            {
                                                !this.state.loading && `
                                                ${this.capitalize(moment(this.state.auction.date_from).format('dddd D'))} de 
                                                ${moment(this.state.auction.date_from).format('MMMM')}, 
                                                ${moment(this.state.auction.date_from).format('HH:mm')} hs`
                                            }
                                        </label>
                                        <br />
                                        <label style={{ color: '#323232', fontWeight: 'bold' }}>
                                            {
                                                !this.state.loading && ` 
                                                ${this.capitalize(moment(this.state.auction.date_to).format('dddd D'))} de 
                                                ${moment(this.state.auction.date_to).format('MMMM')}, 
                                                ${moment(this.state.auction.date_to).format('HH:mm')} hs`
                                            }
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row date-from-to mobile">
                                <div className="col-12">
                                    <img src={calendar} alt="calendar" className=""
                                        style={{ margin: 'auto', alignItems: 'center', justifyContent: 'center', display: 'flex', width: '50px', height: '50px' }} />
                                    <label style={{ marginBottom: '0rem', marginTop: '1rem' }}>INICIO</label>
                                    <label style={{ color: '#323232', fontWeight: 'bold' }}>
                                        {
                                            !this.state.loading && `
                                                ${this.capitalize(moment(this.state.auction.date_from).format('dddd D'))} de 
                                                ${moment(this.state.auction.date_from).format('MMMM')}, 
                                                ${moment(this.state.auction.date_from).format('HH:mm')} hs`
                                        }
                                    </label>
                                    <br />
                                    <label style={{ marginBottom: '0rem' }}>CIERRE</label>
                                    <label style={{ color: '#323232', fontWeight: 'bold' }}>
                                        {
                                            !this.state.loading && ` 
                                                ${this.capitalize(moment(this.state.auction.date_to).format('dddd D'))} de 
                                                ${moment(this.state.auction.date_to).format('MMMM')}, 
                                                ${moment(this.state.auction.date_to).format('HH:mm')} hs`
                                        }
                                    </label>
                                </div>
                            </div>
                        </div>
                        {
                            /*this.state.auction.catalog !== '' && this.state.auction.catalog !== null ?
                                <div className="col-12 col-md-2 mr-auto text-right">
                                    <a className="download-catalog" href={`${Constants.BASE_URL}${this.state.auction.catalog}`} target="_blank" style={{ width: 'auto' }}>
                                        <img src={calendar} style={{ width: '50px', height: '50px', cursor: 'pointer' }} />
                                        <div>
                                            <label className="pdf">PDF</label>
                                            <br />
                                            <label className="download">DESCARGAR CATÁLOGO</label>
                                        </div>
                                    </a>
                                </div>
                                : <div className="col-12 col-md-2 mr-auto" />*/
                        }
                        <div className="col-12 col-md-10 ml-auto mr-auto"
                            dangerouslySetInnerHTML={{ __html: this.state.auction.description }} />
                    </div>

                    {/*<div className="row" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                        <div className="col-12 text-center">
                            {
                                this.state.auction.pending &&
                                <h5 className="auction-in-proccess">
                                    Inicia a la hora {`${moment(this.state.auction.date_from).format('HH:mm')} - `}Faltan
                                    {` ${this.state.auction.timeLeft.hour}:${this.state.auction.timeLeft.minute}:${this.state.auction.timeLeft.second}`}
                                </h5>
                            }
                            {
                                Object.values(this.state.auction.lots).filter(item => this._countDown({ item }) !== null).length > 0 || this.state.auction.status === 'Cerrando subasta' ?
                                    <h5 class='auction-in-proccess red-status'>Cerrando subasta</h5>
                                    :
                                    this.state.auction.status === 'Subasta en proceso' ?
                                        <h5 className="auction-in-proccess green-status">
                                            {this.state.auction.status} - Comienzo cierre {`${this.capitalize(moment(this.state.auction.date_to).format('dddd D'))} hora ${moment(this.state.auction.date_to).format('HH:mm')}`}
                                        </h5>
                                        :
                                        this.state.auction.status === 'Subasta finalizada' ?
                                            <h5 class='red-status'>{this.state.auction.status}</h5>
                                            :
                                            <h5>
                                                {!this.state.loading && `Comienzo ${moment(this.state.auction.date_from).format('dddd D')} de ${moment(this.state.auction.date_from).format('MMMM')}
                                                 hora ${moment(this.state.auction.date_from).format('HH:mm')}`}
                                            </h5>
                            }
                        </div>
                    </div>*/}
                    <div style={{ marginTop: '2rem' }}>
                        <div className="row">
                            {
                                Object.values(this.state.auction.lots).map((item, index) =>
                                    this._countDown({ item }) !== null &&
                                    <this.card item={item} index={index} />
                                )
                            }
                        </div>
                        <div className="row">
                            {
                                Object.values(this.state.auction.lots).map((item, index) =>
                                    (item.status === Constants.STATUS_IN_PROGRESS || item.status === Constants.STATUS_INITIAL) && this._countDown({ item }) === null &&
                                    <this.card item={item} index={index} />
                                )
                            }
                        </div>
                        <div className="row">
                            {
                                Object.values(this.state.auction.lots).map((item, index) =>
                                    item.status !== Constants.STATUS_IN_PROGRESS && item.status !== Constants.STATUS_INITIAL && this._countDown({ item }) === null &&
                                    <this.card item={item} index={index} />

                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(logoutAction());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionDetail);